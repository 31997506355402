import React from "react"
import { Text, Container, Link, Heading, Flex, Button, Box, Grid } from "@chakra-ui/react"
import type { PageProps } from "@root/types/global"
import { AccountIcon, ArrowRightIcon, ArrowUpIcon, CartIcon, CrossIcon, HeartIcon, MenuIcon, SearchIcon, ShareIcon } from "@app/theme/icons"

const StyleGuide: React.FC<PageProps<any>> = () => (
  <Container as="section" maxW={"3xl"} px={[3, 4]}>
    <Heading size="h1" as="h1" color="black">
      Style Guide
    </Heading>
    <Heading size="h5" as="h5" mb="10" color="black">
      Typography
    </Heading>

    <Heading size="display" as="h1" mb="8">
      Heading 1 (display)
    </Heading>
    <Heading size="h1" as="h1" mb="8">
      Heading 1
    </Heading>
    <Heading size="h2" as="h2" mb="8">
      Heading 2
    </Heading>
    <Heading size="h3" as="h3" mb="8">
      Heading 3
    </Heading>
    <Heading size="h4" as="h4" mb="8">
      Heading 4
    </Heading>
    <Heading size="h5" as="h5" mb="8">
      Heading 5
    </Heading>
    <Heading size="h6" as="h6" mb="8">
      Heading 6
    </Heading>
    <Text textStyle="paragraph" as="p" mb="8">
      {"Lorem ipsum dolor sit amet, consectetur adipiscing elit, "}
      <Text as="strong">{"sed do eiusmod tempor"}</Text>
      {" incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud "}
      <Link href="#" variant="inline">
        {"exercitation ullamco"}
      </Link>
      {
        " laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
      }
    </Text>
    <Text textStyle="paragraph2" as="p" mb="8">
      {"Lorem ipsum dolor sit amet, consectetur adipiscing elit, "}
      <Text as="strong">{"sed do eiusmod tempor"}</Text>
      {" incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud "}
      <Link href="#" variant="inline">
        {"exercitation ullamco"}
      </Link>
      {
        " laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
      }
    </Text>
    <Text textStyle="pricing" as="p" mb="8">
      {"Pricing"}
    </Text>
    <Text textStyle="breadcrumbs" mb="8">
      Breadcrumbs | <Text as="strong">Breadcrumbs (bold)</Text>
    </Text>
    <Text textStyle="navigation1" mb="8">
      Navigation 1
    </Text>
    <Text textStyle="navigation2" mb="8">
      Navigation 2
    </Text>
    <Text textStyle="navigation3" mb="8">
      Navigation 3
    </Text>
    <Text textStyle="button1" mb="8">
      Button 1
    </Text>
    <Text textStyle="button2" mb="8">
      Button 2
    </Text>
    <Text textStyle="button3" mb="8">
      Button 3
    </Text>
    <Text textStyle="filters" mb="16">
      Filters
    </Text>

    <Heading size="h5" as="h5" mb="8" color="black">
      Icons
    </Heading>
    <Flex mb="16" wrap="wrap">
      <MenuIcon />
      <SearchIcon />
      <CrossIcon />
      <AccountIcon />
      <CartIcon />
      <ArrowRightIcon />
      <ArrowUpIcon />
      <ShareIcon />
      <HeartIcon />
    </Flex>

    <Heading size="h5" as="h5" mb="8" color="black">
      CTA Buttons
    </Heading>
    <Grid templateColumns="repeat(1, 1fr)" gap={6} mb="16">
      <Box w="100%" p="4">
        <Button>Button Solid</Button>
      </Box>
      <Box w="100%" p="4">
        <Button variant="outline">Button Outline</Button>
      </Box>
      <Box w="100%" p="4" bg="grey.900">
        <Button variant="inverted">Button Inverted</Button>
      </Box>
    </Grid>

    <Heading size="h5" as="h5" mb="10" color="black">
      Text Buttons
    </Heading>
    <Grid templateColumns="repeat(1, 1fr)" gap={6} mb="16">
      <Box w="100%" p="4">
        <Button variant="outline" size="sm">
          Shop Now
        </Button>
      </Box>
      <Box w="100%" p="4" bg="grey.900">
        <Button variant="inverted" size="sm">
          Shop Now
        </Button>
      </Box>
    </Grid>
  </Container>
)

export default React.memo(StyleGuide)
